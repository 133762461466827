.QuestSystemContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.QuestSystemContainer h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 130%;
    color: #FC8836;
    text-align: left;
}

.QuestCarousel {
    cursor: grab;
}


@media only screen and (max-width: 1200px) {
    .QuestSystemContainer h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        color: #FC8836;
    }
}

@media only screen and (max-width: 480px) {
    .QuestSystemContainer h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: #FC8836;
    }
}
