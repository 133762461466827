.TimelineContainer {
    display:flex;
    flex-direction: column;
    width: 1200px;
    padding-top: 5rem;
}

.TimelineContainer h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #000000;  
}

.TimelineBodyContainer {
    display:flex;
    flex-direction: column;
    width: 1200px;
    position: absolute;
    margin-top: 129px;
    row-gap: 80px;
}

.TimelineCenter {
    display: flex;
    min-width: 10%;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: top;
}

.TimelineLine {
    position: relative;
    height: 2130px;
    width: 8px;
    background: rgb(219, 85, 83);
    background: -webkit-linear-gradient(top, #F3ABF2 0%, #FC8836 100%);
    background: linear-gradient(to bottom, #F3ABF2 0%, #FC8836 100%);
    z-index: 0;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);
  }

.TimeLineRow {
    display:flex;
    width: 1200px;
    min-height: 50px;
    flex-direction: row;
    column-gap: 50px;
    justify-content: center;
    align-items: center;
}

#CampaignRow {
    height: 300px;
}

#RewardsRow {
    height: 500px;
    margin-top: -130px;
}

#RewardsRowImg {
    border-radius: 20px;
    width: 400px;
    height: 500px;
}

#Tier1Row img{
    margin-top: -260px;
}

#Tier1Row > .TimeLineRowLeft {
    margin-top: -140px;
}

#Tier2Row {
    margin-top: -140px;
}

#Tier3Row {
    margin-top: -45px;
}

#Final {
    margin-top: 10px;
}

.TimeLineRowLeft {
    display:flex;
    height: 100%;
    width: 540px;
    justify-content: end;
    align-items: flex-start;
}

.TimeLineRowCenter {
    display: flex;
    width: max-content;
    height: 100%;
    justify-content: center;
    
}

#CenterDot {
    align-items: center;
}

#TopDot {
    align-items: flex-start;
}

.TimeLineRowRight {
    display:flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
    width: 540px;
    justify-content: flex-start;
    align-items: flex-start;
}

#RightCenter {
    justify-content: center;
}

.TimeLineRowLeft p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    background: linear-gradient(271.66deg, #FC8836 6.86%, #F3ABF2 96.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: end;
    padding: 0 30px 0 30px;
    margin: 0;
}

.TimeLineRowRight p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    background: linear-gradient(271.66deg, #FC8836 6.86%, #F3ABF2 96.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: start;
    padding: 0 30px 0 30px;
    margin: 0;
}
.TimelinePointForm {
    padding: 0 0 0 30px;
}

.TimelinePointForm h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    text-align: left;
    color: #000000;
    margin: 0;
}

.TimelinePointForm ul {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: #000000;
    text-align: left;
}

.dot1 {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
  }

.dot2 {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    z-index: 1;
}

.CompleteButtonContainer2 {
    display:flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 3rem 0;
    justify-self: flex-start;
    cursor: pointer;
}

.TimelineCardContainer {
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 550px;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    height: 180px;
}

.TimelineCardContainer h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    text-align: right;
    color: #FFFFFF;
    padding: 0 2rem 0 2rem;
    margin-bottom: 1rem;
}

.TimelineCardContainer h2 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: right;
    color: #FFFFFF;
    padding: 0 2rem 0 2rem;
    margin-top: 0;
    line-height: 30px;
}

#TextAlignLeft h1{
    text-align: left;
}

#TextAlignLeft h2{
    text-align: left;
}

@media only screen and (max-width: 1200px) {
    .TimelineContainer {
        width: 800px;
    }

    .TimelineBodyContainer {
        width: 800px;
    }

    .TimeLineRow {
        width: 800px;
        column-gap: 20px;
    }

    .TimeLineRowLeft {
        width: 360px;
    }

    .TimeLineRowLeft p {
        font-size: 24px;
        padding: 0 20px 0 20px;
    }

    .TimeLineRowRight {
        width: 360px;
    }

    .TimeLineRowRight p {
        font-size: 24px;
        padding: 0 20px 0 20px;
    }

    .TimelinePointForm {
        padding: 0 0 0 30px;
    }
    
    .TimelinePointForm h1 {
        font-size: 16px;
    }

    .TimelinePointForm ul {
        font-size: 16px;
    }

    #RewardsRowImg {
        width: 360px;
        height: auto;
    }

    #Tier1Row img{
        width: 360px;
        height: auto;
        margin-top: -260px;
    }

    #Tier2Row {
        margin-top: -90px;
    }
    

    #Tier2Row img{
        width: 360px;
        height: auto;
    }

    .TimelineCardContainer {
        width: 350px;
        height: 150px;
    }
    
    .TimelineCardContainer h1 {
        font-size: 20px;
    }
    
    .TimelineCardContainer h2 {
        font-size: 14px;
        line-height: 20px;
    }

    .TimelineLine {
        height: 1930px;
      }
}

@media only screen and (max-width: 1000px) {
    .TimelineContainer {
        width: 700px;
    }

    .TimelineBodyContainer {
        width: 700px;
    }

    .TimeLineRow {
        width: 700px;
        column-gap: 20px;
    }

    .TimeLineRowLeft {
        width: 300px;
    }

    .TimeLineRowLeft p {
        font-size: 20px;
        padding: 0 20px 0 20px;
    }

    .TimeLineRowRight {
        width: 300px;
    }

    .TimeLineRowRight p {
        font-size: 20px;
        padding: 0 20px 0 20px;
    }

    .TimelinePointForm {
        padding: 0 0 0 30px;
    }
    
    .TimelinePointForm h1 {
        font-size: 14px;
    }

    .TimelinePointForm ul {
        font-size: 14px;
    }

    #RewardsRowImg {
        width: 300px;
        height: auto;
    }

    #Tier1Row img{
        width: 300px;
        height: auto;
        margin-top: -260px;
    }

    #Tier2Row {
        margin-top: -70px;
    }
    

    #Tier2Row img{
        width: 300px;
        height: auto;
    }

    .TimelineCardContainer {
        width: 300px;
        height: 150px;
    }
    
    .TimelineCardContainer h1 {
        font-size: 20px;
    }
    
    .TimelineCardContainer h2 {
        font-size: 14px;
        line-height: 20px;
    }

    .TimelineLine {
        top:60px;
        height: 1850px;
      }
}

@media only screen and (max-width: 786px) {
    .TimelineContainer {
        width: 450px;
    }

    .TimelineContainer h1 {
        font-size: 32px;
    }

    .TimelineBodyContainer {
        width: 450px;
        row-gap: 40px;
    }

    .TimeLineRow {
        width: 450px;
        column-gap: 15px;
    }

    .TimeLineRowLeft {
        width: 200px;
    }

    .TimeLineRowLeft p {
        font-size: 16px;
        padding: 0 10px 0 10px;
    }

    .TimeLineRowRight {
        width: 200px;
    }

    .TimeLineRowRight p {
        font-size: 16px;
        padding: 0 10px 0 10px;
    }

    .TimelinePointForm {
        padding: 0 0 0 20px;
    }
    
    .TimelinePointForm h1 {
        font-size: 12px;
    }

    .TimelinePointForm ul {
        font-size: 10px;
        padding-left: 0;
    }

    #RewardsRow {
        height: 500px;
        margin-top: -220px;
    }

    #RewardsRowImg {
        width: 190px;
        height: auto;
        border-radius: 10px;
        margin-top: -40px;
    }

    #Tier1Row {
        margin-top: -180px;
    }

    #Tier1Row img{
        width: 190px;
        height: auto;
        margin-top: -80px;
        border-radius: 10px;
    }

    #Tier2Row {
        margin-top: -60px;
    }
    

    #Tier2Row img{
        width: 190px;
        height: auto;
        border-radius: 10px;
        margin-top: -20px;
    }

    #Tier3Row {
        margin-top: -10px;
    }

    .TimelineCardContainer {
        width: 190px;
        height: 100px;
        border-radius: 10px;
    }

    .TimelineCardContainer h1 {
        font-size: 14px;
        padding: 0.25rem 0.25rem 0 0.25rem;
        margin: 0.25rem;
    }
    
    .TimelineCardContainer h2 {
        font-size: 10px;
        line-height: 14px;
        padding: 0 0.25rem 0 0.25rem;
        margin: 0.25rem;
    }

    .TimelineLine {
        height: 1200px;
      }
}

@media only screen and (max-width: 480px) {

    .TimelineContainer {
        width: 100%;
        padding-top: 2rem;
    }

    .TimelineContainer h1 {
        font-size: 16px;
    }

    .TimelineBodyContainer {
        width: 100%;
        row-gap: 40px;
        margin-top: 50px;
    }

    .TimeLineRow {
        width: 100%;
        column-gap: 5%;
    }

    .TimeLineRowLeft {
        width: 40%;
    }

    .TimeLineRowLeft p {
        font-size: 14px;
        padding: 0 5px 0 5px;
    }

    .TimeLineRowRight {
        width: 40%;
    }

    .TimeLineRowRight p {
        font-size: 14px;
        padding: 0 5px 0 5px;
    }

    .TimelinePointForm {
        padding: 0 0 0 5px;
    }
    
    .TimelinePointForm h1 {
        font-size: 10px;
    }

    .TimelinePointForm ul {
        font-size: 10px;
        padding-left: 0;
    }

    #RewardsRow {
        height: 500px;
        margin-top: -260px;
    }

    #RewardsRowImg {
        width: 120px;
        height: auto;
        border-radius: 10px;
        margin-top: 0px;
    }

    .CompleteButtonContainer2 {
        margin-top: -20px;
    }
    

    #Tier1Row {
        margin-top: -180px;
    }

    #Tier1Row img{
        width: 120px;
        height: auto;
        margin-top: -80px;
        border-radius: 10px;
    }

    #Tier2Row {
        margin-top: -30px;
    }
    

    #Tier2Row img{
        width: 120px;
        height: auto;
        border-radius: 10px;
        margin-top: -20px;
    }

    #Tier3Row {
        margin-top: -40px;
    }

    .TimelineCardContainer {
        width: 120px;
        height: 150px;
        border-radius: 10px;
    }

    .TimelineCardContainer h1 {
        font-size: 12px;
        padding: 0.25rem 0.25rem 0 0.25rem;
        margin: 0.25rem;
    }
    
    .TimelineCardContainer h2 {
        font-size: 10px;
        line-height: 14px;
        padding: 0 0.25rem 0 0.25rem;
        margin: 0.25rem;
    }

    .TimelineLine {
        top: 20px;
        height: 1120px;
      }

    .dot1 {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        z-index: 1;
        display: inline-block;
      }
    
    .dot2 {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: inline-block;
        z-index: 1;
    }
}