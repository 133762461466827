.CommonButton {
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    border: none;
    width: 100%;
    height: 60px;
    font-family: 'poppins';
    font-weight: 900;
    font-size: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    justify-self: center;
    align-self: center;
    cursor: pointer;
}

#CommonButtonGreyed {
    background: #A9A9A9;
}

#CommonButtonOutlineGreyed {
    background: transparent;
    border: 1px solid #A9A9A9;
    color: #A9A9A9;
}

@media only screen and (max-width: 480px) {
    .CommonButton {
        border-radius: 10px;
        width: 300px;
        height: 40px;
        font-size: 12px;
    }
}
