.TitleContainer {
    display:flex;
    width: 100%;
    height: 100px;
    background-color: #EFEFEF;
    justify-content: center;
    align-items: center;
    margin: -100px 0 0 0;
}

.TitleContainer h1 {
    text-align: center;
    color: Black;
    font-size: 25px;
    font-family: Poppins-SemiBold;
    font-weight: 600;
    letter-spacing: 0.1em;
    /* padding: 2rem 1rem 2rem 1rem; */
}

@media only screen and (max-width: 1920px) {
    .TitleContainer h1 {
        font-size: 32px;
    }
}

@media only screen and (max-width: 1600px) {
    .TitleContainer h1 {
        font-size: 28px;
    }
}

@media only screen and (max-width: 1200px) {
    .TitleContainer {
        margin: -30px 0 0 0;
    }

    .TitleContainer h1 {
        font-size: 24px;
    }

}

@media only screen and (max-width: 1000px) {
    .TitleContainer h1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 786px) {
    .TitleContainer {
        height: 80px;
        margin: -20px 0 0 0;
    }

    .TitleContainer h1 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .TitleContainer {
        height: 60px;
        margin: -20px 0 0 0;
    }

    .TitleContainer h1 {
        font-size: 12px;
    }
}

@media only screen and (max-width: 300px) {
    .TitleContainer h1 {
        font-size: 12px;
    }
}