.LoginButton {
    display: flex;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    width: 160px;
    height: 60px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.LoginButton h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    cursor: pointer;
}

#mintmodalLogin {
    display: flex;
    border: 2px solid #FC8836;
    border-radius: 40px;
    width: 260px;
    height: 60px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: none;
}

#mintmodalLogin h1 {
    font-family: "poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #FC8836;
    padding: 0;
}

#lovemeterLogin {
    width: 300px;
    height: 60px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}

#lovemeterLogin h1{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

#timelineLogin {
    width: 300px;
    height: 60px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
}

#timelineLogin h1{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.ConnectButton {
    width: 600px;
    height: 90px;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ConnectButton h1 {
    text-align: center !important;
    color: white !important;
    font-size: 30px !important;
    font-family: DIN-Black !important;
    font-weight: 900 !important;
    margin: 0 !important;
}

.MintModalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.MintModalTop {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.MintModalLeft {
    display: flex;
    width: 50%;
    padding: 2rem;
}

.MintModalRight {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    row-gap: 1rem;
    padding: 2rem;
}

.MintModalRight h1 {
    font-family: DIN-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 130%;
    padding: 0;
    margin: 0;
    /* or 65px */

    display: flex;
    align-items: center;

    color: #000000;
}

.MintModalPrice {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.MintModalPrice img {
    width: 52px;
    height: 52px;
}

.MintModalPrice h1 {
    padding: 1rem;
    font-family: DIN-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 130%;
    /* or 65px */

    display: flex;
    align-items: center;

    color: #000000;
}

.MintModalBottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    justify-self: center;
    column-gap: 5rem;
    padding: 3rem 0 3rem 0;
    border: 1px solid #929292;
}

.MintModalBottomImg {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.MintModalBottomImg img{
    width: 500px;
    height: auto;
}

.MintModalBottomRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MintModalBottomRight h2 {
    font-family: DIN-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    padding: 0;
    margin: 0;
}

.MintModalBottomRight h1 {
    font-family: DIN-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 46px;
    line-height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    padding: 0;
    margin: 3rem 0 0 0;
}

.SmallGiftButton {
    display: flex;
    border: 2px solid #FC8836;
    border-radius: 40px;
    width: 260px;
    height: 60px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: DIN-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 27px;
    color: #FC8836;
}

@media only screen and (max-width: 1600px) {
    .LoginButton {
        width: 120px;
        height: 50px;
    }

    .LoginButton h1{
        font-size: 16px;
    }
}

@media only screen and (max-width: 1200px) {
    .LoginButton {
        width: 100px;
        height: 40px;
    }

    .LoginButton h1{
        font-size: 15px;
    }
}

@media only screen and (max-width: 480px) {

    .LoginButton {
        border-radius: 5px;
        width: 60px;
        height: 30px;
    }

    .LoginButton h1{
        font-family: 'poppins';
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
    }
    #timelineLogin {
        width: 120px;
        height: 22px;
    }

    #timelineLogin h1{
        font-size: 8px;
    }

    #lovemeterLogin {
        width: 150px;
        height: 30px;
        border-radius: 5px;
    }

    #lovemeterLogin h1{
        font-size: 12px;
    }

    #mintmodalLogin {
        display: flex;
        border: 2px solid #FC8836;
        border-radius: 40px;
        width: 150px;
        height: 35px;
        text-align: center;
        justify-content: center;
        align-items: center;
        background: none;
    }

    #mintmodalLogin h1 {
        font-family: "poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        color: #FC8836;
    }

    .ConnectButton {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
