.GiftButton {
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    width: 100%;
    height:60px;
    font-family: 'poppins';
    font-style: 'normal';
    font-weight: 900;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.GiftModalOverallContent {
    display: block;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    position: relative;
}

.GiftModalContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    row-gap: 50px;

}

.GiftModalContent h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #000000;
    margin: 0;
}

.GiftTokenSelector {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.GiftTokenSelectorLeft {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.GiftTokenSelectorLeft img {
    width: 350px;
    height: auto;
    border-radius: 20px;
    border: 10px solid #ffffff;
}

.GiftTokenSelectorLeft p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    margin: 0;
}

.GiftTokenSelectorRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.GiftTokenSelectorRight img {
    width: 350px;
    height: auto;
    border-radius: 20px;
    border: 10px solid #ffffff;
}

.GiftTokenSelectorRight p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    margin: 0;
}

#selected > img {
    border: 10px solid #FC8836;
    border-radius: 20px;
}

#selected > p {
    color: #FC8836;
}

.NextButton {
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    border: none;
    width: 80%;
    height: 60px;
    font-family: 'poppins';
    font-weight: 900;
    font-size: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    justify-self: center;
    align-self: center;
    cursor: pointer;
}

#NextButtonGreyed {
    background: #A9A9A9;
}

@media only screen and (max-width: 786px) {
    .GiftButton {
        border-radius: 10px;
        width: 100%;
        height:40px;
        font-size: 14px;
    }

    .GiftModalContent h1 {
        font-size: 24px;
    }

    .GiftTokenSelectorLeft img {
        width: 160px;
    }

    .GiftTokenSelectorLeft p {
        font-size: 12px;
        margin: auto;
    }

    .GiftTokenSelectorRight img {
        width: 160px;
    }

    .GiftTokenSelectorRight p {
        font-size: 14px;
        margin: auto;
    }

    .NextButton {
        border-radius: 10px;
        width: 300px;
        height: 40px;
        font-size: 12px;
    }

}

@media only screen and (max-width: 480px) {
    .GiftButton {
        border-radius: 10px;
        width: 100%;
        height:23px;
        font-size: 14px;
    }

    .GiftModalContent h1 {
        font-size: 20px;
    }

    .GiftTokenSelectorLeft img {
        width: 130px;
        border: 5px solid #ffffff;
    }

    .GiftTokenSelectorLeft p {
        font-size: 12px;
        margin: auto;
    }

    .GiftTokenSelectorRight img {
        width: 130px;
        border: 5px solid #ffffff;
    }

    .GiftTokenSelectorRight p {
        font-size: 12px;
        margin: auto;
    }

    #selected > img {
        border: 5px solid #FC8836;
        border-radius: 20px;
    }

    .NextButton {
        border-radius: 10px;
        width: 300px;
        height: 40px;
        font-size: 12px;
    }

}