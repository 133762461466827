.LoveMeterContainer {
    display:flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LoveMeterContainer h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.LoveMeterTrackerContainer {
    display: flex;
    width: 1000px;
    flex-direction: row;
    column-gap: 6rem;
    padding: 0 0 2rem 0;
}

.LoveMeterLeft {
    display: flex;
    width: 300px;
}

.LoveMeterRight {
    display: flex;
    width: 1040px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.LoveMeterRight h1 {
    padding: 3rem 0 2rem 0;
    font-family: DIN-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #000000;
}

.TierSection {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0 1.5rem 0;
}

.TierSection p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    align-items: center;
    text-align: left;
    color: #000000;
    line-height: 130%;
}

.TierBoxBorder {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 66px;
    border-radius: 50px;
    background: linear-gradient(101.93deg, #FC8836 3.86%, #F3ABF2 82.49%);
    padding: 5px;
}
.TierBoxBorderFill {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 66px;
    border-radius: 50px;
    background: linear-gradient(101.93deg, #FC8836 3.86%, #F3ABF2 82.49%);
    padding: 5px;
}

.TierBox {
    width: 187px;
    height: 65px;
    background: white;
    border-radius: 50px;
    font-family: 'poppins';
    font-style: bold;
    font-weight: 900;
    font-size: 25px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
}

.TierBoxFill {
    width: 187px;
    height: 65px;
    background: linear-gradient(101.93deg, #FC8836 3.86%, #F3ABF2 82.49%);
    border-radius: 50px;
    font-family: 'poppins';
    font-style: bold;
    font-weight: 900;
    font-size: 25px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: white
}

.LoveMeterHeartsContainer {
    display: flex;
    flex-direction: row;
    row-gap: 1rem;
    padding: 2rem 0 2rem 0;
}

.LoveMeterCTA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1000px;
    height: 300px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.LoveMeterCTA p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
    color: #000000;
}

.CompleteButtonContainer {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.PointSystemOverallContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 3rem 0 0 0;
}

.PointSystemOverallContainer h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: #000000;
}

.PointSystemInfoContainer {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.PointSystemInfoBorder {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 780px;
    height: 124px;
    border-radius: 20px;
    background: linear-gradient(180deg, #FC8836 0%, #F3ABF2 100%);
    padding: 5px 5px 5px 5px;
    box-shadow: 0 0 5px #F3ABF2;

}
/* box-shadow: inset 0 0 4px 2px white */

.PointSystemInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 15px;
    width:779px;
    height: 123px;
    flex-direction: row;
    box-shadow: inset 0 0 5px #F3ABF2;
}

.PointSystemInfoLeft {
    width:30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0px;
}

.PointSystemInfoLeft h1 {
    font-family: "argent-cf", serif;
    font-weight: 900;
    font-style: normal;
    font-size: 70px;
    align-items: center;
    text-align: center;
    background: linear-gradient(168.46deg, #FC8836 13.47%, #F3ABF2 66.93%);
    text-shadow: 2px solid #FC8836;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    padding: 0;
    margin: -18px 0 0 0;
    -webkit-text-stroke: 1px #FC8836;
}

.PointSystemInfoLeft p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    align-items: center;
    color: #FC8836;
    padding: 0;
    margin: -15px 0 0 0;
}

.PointSystemInfoRight {
    width:70%;
}

.PointSystemInfoRight p {
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #000000;
    line-height: 130%;
}

#PointSystemInfoBorderBig {
    width: 780px;
    height: 196px;
    border-radius: 20px;
}

#PointSystemInfoBig {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 15px;
    width:779px;
    height: 195px;
    flex-direction: column;
    box-shadow: inset 0 0 5px #F3ABF2;
}

#PointSystemInfoBig h1 {
    font-family: "argent-cf", serif;
    font-weight: 900;
    font-style: normal;
    font-size: 60px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    background: linear-gradient(168.46deg, #FC8836 13.47%, #F3ABF2 66.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-stroke: 1px #FC8836;
    margin: 0 0 15px 0;
}

#PointSystemInfoBig p {
    font-family: "poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    align-items: center;
    color: #000000;
    margin: 0;
}

.LoveMeterPointsContainer {
    display: flex;
    width: 1000px;
    height: 240px;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 1rem 0;
    row-gap: 1rem;
}

.LoveMeterPointsContainer h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    padding: 0;
    margin: 0 0 -15px 0;
    color: #FFFFFF;
}

.LoveMeterPointsContainer p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    padding: 0;
    margin: 0;
    color: #FFFFFF;
}

@media only screen and (max-width: 1200px) {

    .LoveMeterTrackerContainer {
        width: 800px;
    }

    .LoveMeterLeft img {
        width: 200px;
    }
    
    .LoveMeterRight {
        width: 600px;
    }

    .TierBox {
        font-size: 24px;
    }
    
    .TierSection p {
        font-size: 20px;
    }

    .LoveMeterPointsContainer {
        width: 800px;
        height: 200px;
    }
    
    .LoveMeterPointsContainer h1 {
        font-size: 80px;
    }
    
    .LoveMeterPointsContainer p {
        font-size: 24px;
    }

    .LoveMeterHeartsContainer {
        width:800px;
        justify-content: center;
    }

    .LoveMeterHeartsContainer img{
        width:250px;
    }

    .LoveMeterCTA {
        width: 800px;
        height: 250px;
    }
    
    .LoveMeterCTA p {
        font-size: 24px;
    }
}

@media only screen and (max-width: 1000px) {

    .LoveMeterTrackerContainer {
        width: 700px;
        column-gap: 3rem;
    }

    .LoveMeterLeft img {
        width: 175px;
    }
    
    .LoveMeterRight {
        width: 525px;
    }

    .TierSection {
        display: flex;
        flex-direction: column;
        padding: 1rem 0 1rem 0;
    }

    .TierBox {
        font-size: 24px;
    }
    
    .TierSection p {
        font-size: 20px;
    }

    .LoveMeterPointsContainer {
        width: 700px;
        height: 200px;
    }
    
    .LoveMeterPointsContainer h1 {
        font-size: 80px;
    }
    
    .LoveMeterPointsContainer p {
        font-size: 24px;
    }

    .LoveMeterHeartsContainer {
        width:700px;
        justify-content: center;
    }

    .LoveMeterHeartsContainer img{
        width:220px;
    }

    .LoveMeterCTA {
        width: 700px;
        height: 250px;
    }
    
    .LoveMeterCTA p {
        font-size: 24px;
    }

    .PointSystemOverallContainer {
        width: 700px;
    }

    .PointSystemOverallContainer h1 {
        font-size: 36px;
    }

    .PointSystemInfoBorder {
        width: 700px;
        height: 100px;
    }

    .PointSystemInfo {
        width:699px;
        height: 99px;
    }

    .PointSystemInfoLeft h1 {
        font-size: 60px;
    }

    .PointSystemInfoLeft p {
        font-family: 'poppins';
        font-size: 20px;
    }

    .PointSystemInfoRight {
        width:70%;
    }

    .PointSystemInfoRight p {
        font-size: 20px;
    }

    #PointSystemInfoBorderBig {
        width: 700px;
        height: 150px;
    }

    #PointSystemInfoBig {
        width:699px;
        height: 149px;
    }

    #PointSystemInfoBig h1 {
        font-size: 50px;
    }

    #PointSystemInfoBig p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 786px) {

    .LoveMeterContainer h1 {
        font-size: 32px;
    }

    .LoveMeterTrackerContainer {
        width: 450px;
        flex-direction: column;
        align-items: center;
    }

    .LoveMeterLeft {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .LoveMeterLeft img {
        width: 200px;
    }
    
    .LoveMeterRight {
        width: 400px;
        align-items: center;
        justify-content: center;
    }

    .TierSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .TierBox {
        font-size: 20px;

    }
    
    .TierSection p {
        font-size: 16px;
        text-align: center;
    }

    .LoveMeterPointsContainer {
        width: 300px;
        height: 100px;
    }
    
    .LoveMeterPointsContainer h1 {
        font-size: 40px;
    }
    
    .LoveMeterPointsContainer p {
        font-size: 16px;
    }

    .LoveMeterHeartsContainer {
        width:300px;
        justify-content: center;
    }

    .LoveMeterHeartsContainer img{
        width:90px;
    }

    .LoveMeterCTA {
        width: 400px;
        height: 250px;
    }
    
    .LoveMeterCTA p {
        font-size: 20px;
    }

    .PointSystemOverallContainer {
        width: 400px;
    }

    .PointSystemOverallContainer h1 {
        font-size: 32px;
    }

    .PointSystemInfoBorder {
        width: 400px;
        height: 100px;
    }

    .PointSystemInfo {
        width:399px;
        height: 99px;
    }

    .PointSystemInfoLeft h1 {
        font-size: 40px;
    }

    .PointSystemInfoLeft p {
        font-family: 'poppins';
        font-size: 16px;
        margin-top: auto;
    }

    .PointSystemInfoRight {
        width:70%;
    }

    .PointSystemInfoRight p {
        font-size: 16px;
    }

    #PointSystemInfoBorderBig {
        width: 400px;
        height: 150px;
    }

    #PointSystemInfoBig {
        width:399px;
        height: 149px;
    }

    #PointSystemInfoBig h1 {
        font-size: 35px;
    }

    #PointSystemInfoBig p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {

    .LoveMeterContainer h1 {
        font-size: 16px;
    }

    .LoveMeterTrackerContainer {
        width: 300px;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .LoveMeterLeft {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1.5rem;
    }

    .LoveMeterLeft img {
        width: 150px;
    }
    
    .LoveMeterRight {
        width: 300px;
        align-items: center;
        justify-content: center;
    }

    .TierSection {
        padding: 0;
    }
    
    .TierBoxBorder {
        width: 150px;
        height: 50px;
        border-radius: 30px;
        padding: 3px;
    }
    
    
    .TierBox {
        width: 149px;
        height: 49px;
        border-radius: 30px;
        font-size: 12px;
    }

    .TierSection p {
        font-size: 12px;
        text-align: center;
    }

    .LoveMeterPointsContainer {
        width: 300px;
        height: 75px;
        row-gap: 0;
    }
    
    .LoveMeterPointsContainer h1 {
        font-size: 24px;
        margin: 0;
    }
    
    .LoveMeterPointsContainer p {
        font-size: 12px;
    }

    .LoveMeterHeartsContainer {
        width:300px;
        justify-content: center;
    }

    .LoveMeterHeartsContainer img{
        width:90px;
    }

    .LoveMeterCTA {
        width: 300px;
        height: 150px;
        padding: 0;
    }
    
    .LoveMeterCTA p {
        font-size: 12px;
    }

    .PointSystemOverallContainer {
        width: 300px;
        padding: 1rem 0 0 0;
    }

    .PointSystemOverallContainer h1 {
        font-size: 16px;
    }

    .PointSystemInfoBorder {
        width: 300px;
        height: 80px;
        padding: 3px;
    }

    .PointSystemInfo {
        width:299px;
        height: 79px;
    }

    .PointSystemInfoLeft h1 {
        font-size: 24px;
        margin: auto;
    }

    .PointSystemInfoLeft p {
        font-family: 'poppins';
        font-size: 12px;
        margin: auto;
    }

    .PointSystemInfoRight {
        width:70%;
    }

    .PointSystemInfoRight p {
        font-size: 12px;
    }

    #PointSystemInfoBorderBig {
        width: 300px;
        height: 120px;
    }

    #PointSystemInfoBig {
        width:299px;
        height: 119px;
    }

    #PointSystemInfoBig h1 {
        font-size: 20px;
    }

    #PointSystemInfoBig p {
        font-size: 12px;
    }
}