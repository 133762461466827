.PreRegButtonContainer {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.PreRegButton {
    width: 400px;
    height: 70px;
    border: 4px solid #FFFFFF;
    background: none;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.2));
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.PreRegButton:hover {
    cursor: pointer;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border: none;
}

.PreRegButton h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
    color: #ffffff;
}

@media only screen and (max-width: 1200px) {
    .PreRegButton {
        width: 350px;
        height: 60px;
        z-index: 5;
    }
    .PreRegButton h1 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 786px) {
    .PreRegButton {
        width: 250px;
        height: 50px;
        z-index: 5;
        border: 3px solid #FFFFFF;
    }
    .PreRegButton h1 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .PreRegButton {
        width: 200px;
        height: 35px;
        z-index: 5;
        border: 2px solid #FFFFFF;
    }
    .PreRegButton h1 {
        font-size: 14px;
    }
}