.PostLoginContainer {
    display: flex;
    width: 80vw;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
}

.LoadingAPIContainer {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
}

.ViewSelector {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
}

.ViewSelector h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    display: flex;
    align-items: center;

    color: #000000;
}