.BannerWithSubtle {
    width: 100%;
    padding: 24px 0;
    text-align: center;
    font-family: 'poppins';
    border-radius: 10px;
    border: 1px solid #A9A9A9;
    margin-bottom: 48px;
}

.BannerSubtitle {
    text-align: center;
    color: #A9A9A9;
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    margin: 0;
}

.BannerTitle {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
    color: #FC8836;
    margin: 8px 0 0 0;
}

@media only screen and (max-width: 480px) {
    .BannerWithSubtle {
        width: 100%;
        margin-top: 12px;
        margin-bottom: 12px;
        padding: 24px 0;
    }

    .BannerSubtitle {
        font-size: 12px;
    }

    .BannerTitle {
        font-size: 16px;
    }

}
