.ProfileContainer {
    display:flex;
    width: 1200px;
    min-height: calc(100vh - 302px);
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
}

.ProfileContainerHeader {
    display:flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.LogoutButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
}

.LogoutButton {
    border-radius: 20px;
    width: 160px;
    height:60px;
    font-family: 'poppins';
    font-weight: 900;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #A9A9A9;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.ProfileContainerHeaderLeft {
    display: flex;
    width: 25%;
}

.ProfileContainerHeaderMiddle {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}

.ProfileContainerHeaderMiddle h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    display: flex;
    text-align: center;
}

.ProfileContainerBody {
    display: flex;
    height: 100%;
    flex-direction: row;
    column-gap: 30px;
}

.ProfileContainerBodyLeft {
    display: flex;
    width:35%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
}

.ProfileContainerBodyRight {
    display: flex;
    width:65%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.ProfileButtonContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
}

.ProfileInformationContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 50px;
}

.ProfilePicture {
    display: flex;
    width: 30%;
    border-radius: 100px;
}

.ProfilePicture img {
    width: 150px;
    height: 150px;
    border-radius: 100px;
}

.ProfileInformation {
    display: flex;
    width: 70%;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
}

.ProfileInformationTop {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.ProfileInformationTop h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    background: linear-gradient(129.5deg, #F3ABF2 10.28%, #FC8836 83.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: left;
    text-fill-color: transparent;
    margin: 0;
}

.ProfileInformationTop p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #FC8836;
    text-align: left;
    margin: 0;
}

.ProfileInformationBtm {
    display: flex;
    flex-direction: column;
}

.ProfileInformationBtm h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    color: #BCBCBC;
    text-align: left;
    margin: 0;
}

.ProfileInformationBtm p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 130%;
    background: linear-gradient(94.91deg, #F3ABF2 7.12%, #FC8836 81.66%);
    text-align: left;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0 0 10px 0;
}

.ProfileInformationBtm p:before {
    content:url('/public/ProfileSmallHeart.png');
}

.ProfileButton {
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    border: none;
    width: 100%;
    height:60px;
    font-family: 'poppins';
    font-size: 20px;
    font-weight: 900;
    line-height: 130%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.ProfileContainerFooter {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.QuestSystemButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.QuestSystemButton {
    margin: auto auto 24px auto;
    width: 90%;
}

.QuestSystemProgressTracker {
    display: flex;
    margin-bottom: 24px;
}

@media only screen and (max-width: 1600px) {
    .ProfileContainer {
        display:flex;
        width: 1000px;
    }
    .ProfileContainerBodyLeft {
        min-width: 350px;
    }
    .ProfileContainerBodyRight {
        min-width: 650px;
    }

    .ProfilePicture img {
        width: 120px;
        height: 120px;
    }

    .ProfileInformationTop h1 {
        font-size: 30px;
    }

    .ProfileInformationTop p {

        font-size: 16px;
    }
}

@media only screen and (max-width: 1200px) {
    .ProfileContainer {
        width: 800px;
        min-width: 800px;
        min-height: calc(100vh - 232px);
    }

    .LogoutButton {
        width: 120px;
        height:60px;
        font-size: 15px;
    }

    .ProfileContainerHeaderMiddle h1 {
        font-size: 32px;
    }

    .ProfileContainerBodyLeft {
        display: flex;
        width:280px;
        min-width: 280px;
    }

    .ProfileContainerBodyRight {
        width:520px;
        min-width: 520px;
    }

    .ProfileButtonContainer {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 100%;
    }

    .ProfileInformationContainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        column-gap: 50px;
    }

    .ProfilePicture {
        display: flex;
        width: 30%
    }

    .ProfilePicture img {
        width: 100px;
        height: 100px;
    }

    .ProfileInformation {
        display: flex;
        width: 70%;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;
    }


    .ProfileInformationTop h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        background: linear-gradient(129.5deg, #F3ABF2 10.28%, #FC8836 83.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-align: left;
        text-fill-color: transparent;
        margin: 0;
    }

    .ProfileInformationTop p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #FC8836;
        text-align: left;
        margin: 0;
    }
}

@media only screen and (max-width: 800px) {
    .ProfileContainer {
        display:flex;
        width: 470px;
        min-width: 470px;
        min-height: calc(100vh - 182px);
    }
    .ProfileContainerHeaderMiddle h1 {
        font-size: 26px;
    }
    .ProfilePicture {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .ProfilePicture img {
        width: 200px;
        height: 200px
    }

    .LogoutButton {
        border-radius: 10px;
        width: 160px;
        height:30px;
        font-size: 12px;
    }

    .ProfileContainerBody {
        display: flex;
        flex-direction: column;
        height: 910px;
        row-gap: 30px;
    }

    .ProfileContainerBodyLeft {
        display: flex;
        width:100%;
        min-width: 300px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
    }

    .ProfileInformationContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position:relative;
        row-gap: 10px;
    }

    .ProfileInformationTop {
        row-gap: 10px;
    }

    .ProfileInformationTop h1 {
        font-size: 24px;
        text-align: center;
    }

    .ProfileInformationTop p {
        font-size: 16px;
        text-align: center;
    }

    .ProfileInformationBtm h1 {
        font-size: 14px;
        text-align: center;
    }

    .ProfileInformationBtm p {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }

    .ProfileButton {
        border-radius: 10px;
        width: 100%;
        height:40px;
        font-size: 14px;
    }

    .ProfileContainerBodyRight {
        width:100%;
        height: 100px;
        min-width: 300px;
        align-items: center ;
        justify-content: flex-end;
    }
}



@media only screen and (max-width: 480px) {
    .ProfileContainer {
        display:flex;
        width: 300px;
        min-width: 300px;
        min-height: calc(100vh - 192px);
    }
    .ProfileContainerHeaderMiddle h1 {
        font-size: 20px;
    }

    .LogoutButton {
        border-radius: 10px;
        width: 160px;
        height:30px;
        font-size: 12px;
    }

    .ProfileContainerBody {
        display: flex;
        flex-direction: column;
        height: 780px;
    }

    .ProfileContainerBodyLeft {
        display: flex;
        width:100%;
        min-width: 300px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 30px;
    }

    .ProfileContainerBodyLeft img {
        width: 100px;
        height: 100px;
    }

    .ProfileInformationContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
    }

    .ProfileInformationTop {
        row-gap: 10px;
    }

    .ProfileInformationTop h1 {
        font-size: 14px;
        text-align: center;
    }

    .ProfileInformationTop p {
        font-size: 12px;
        text-align: center;
    }

    .ProfileInformationBtm h1 {
        font-size: 14px;
        text-align: center;
    }

    .ProfileInformationBtm p {
        font-weight: 600;
        font-size: 14px;
        text-align: center;
    }

    .ProfileButton {
        border-radius: 10px;
        width: 100%;
        height:23px;
        font-size: 14px;
    }

    .ProfileContainerBodyRight {
        width:100%;
        height: 100px;
        min-width: 300px;
        align-items: center ;
        justify-content: flex-end;
    }

    .QuestSystemButton {
        width: 100%;
    }

    .ProfileButtonContainer {
        row-gap: 0.5rem;
    }
}
