.MintButton {
    width: 300px;
    height: 60px;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.MintButton h1 {
    text-align: center;
    color: white;
    font-size: 30px;
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    margin: 0;
}

#bannerMintButton {
    border: 3px solid #FFFFFF;
    background: #FFFFFF;
    border-radius: 20px;
    width: 300px;
    height: 60px;
    background: none;
    cursor: pointer;
}

#navbarMintButton {
    border: 3px solid #FFFFFF;
    background: #FFFFFF;
    border-radius: 20px;
    width: 160px;
    height: 60px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

#navbarMintButton h1{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    background: linear-gradient(109.37deg, #F3ABF2 13.06%, #FC8836 81.87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

#LovechainMintButton h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.ConnectButton {
    width: 100%;
    height: 50px;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ConnectButton h1 {
    text-align: center !important;
    color: white !important;
    font-size: 20px !important;
    font-family: 'poppins' !important;
    font-weight: 900 !important;
    margin: 0 !important;
}

.MintModalOverallContainer {
    display: block;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    overflow: scroll;
}

.MintModalHeader {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
}

.MintModalHeaderCloseIcon {
    display: flex;
    z-index: 1;
    height: 40px;
    position: absolute;
}

.MuiSvgIcon-root {
    margin-right: 8px;
}

.MintModalHeaderText {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.MintModalHeader h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #000000;
}

.MintModalContent {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.MintModalTop {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.MintModalLeft {
    display: flex;
    width: 30%;
    padding: 2rem;
    align-items: center;
    justify-content: center;
}

.MintModalLeft img{
    width: 350px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.MintModalRight {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    row-gap: 1rem;
    padding: 2rem;
}

.MintModalRight h1 {
    display: block;
    font-family: "poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    padding: 0;
    margin: 0;
    color: #000000;
}

.MintModalRight p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    align-items: center;
    text-align: justify;
    color: #000000;
    border-bottom: 1px solid #DAD6D6;
}

.MintModalPrice {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.MintModalPrice img {
    width: 40px;
    height: 40px;
}

.MintModalPrice h1 {
    padding: 0 rem 0 0;
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    display: flex;
    align-items: center;
    color: #000000;
}

.MintModalQuantitySelector {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
}

.MintModalQuantitySelector h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}

.MintModalBottomDesktop {
    display: flex;
    flex-direction: row;
    width: 95%;
    align-self: center;
    justify-self: center;
    column-gap: 30px;
    padding: 1rem 0 1rem 0;
    border: 1px solid #929292;
    border-radius: 20px;
}

.MintModalBottomImgDesktop {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    justify-content: flex-end;
}

.MintModalBottomImgDesktop img{
    width: 450px;
    height: auto;
}

.MintModalBottomRightDesktop {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.MintModalBottomRightWrapperDesktop {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MintModalBottomRightDesktop h2 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    padding: 0;
    margin: 0;
}

.MintModalBottomRightDesktop h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight:700;
    font-size: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    padding: 0 0 1rem 0;
    margin: 0;
}

.MintModalBottomMobileBorder {
    display: none;
}

@media only screen and (max-width: 1600px) {
    #navbarMintButton {
        border: 3px solid #FFFFFF;
        border-radius: 20px;
        width: 120px;
        height: 50px;
    }

    #navbarMintButton h1{
        font-size: 16px;
    }
}

@media only screen and (max-width: 1200px) {
    #navbarMintButton {
        border: 3px solid #FFFFFF;
        border-radius: 20px;
        width: 100px;
        height: 40px;
    }

    #navbarMintButton h1{
        font-size: 14px;
    }

    #bannerMintButton {
        width: 250px;
        height: 40px;
        background: none;
    }

    #bannerMintButton h1{
        font-size: 20px;
    }


    #navbarMintButton {
        border-radius: 5px;
        width: 100px;
        height: 40px;
    }

    #navbarMintButton h1{
        font-family: 'poppins';
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
    }
}

@media only screen and (max-width: 1000px) {
    .MintModalHeader h1 {
        font-size: 32px;
    }

    .MintModalLeft img{
        width: 250px;
    }

    .MintModalBottomImgDesktop img{
        width: 300px;
        height: auto;
    }

    .MintModalRight {
        padding: 1rem;
    }


    .MintModalRight h1 {
        font-size: 20px;
    }
    
    .MintModalRight p {
        font-weight: 400;
        font-size: 16px;
    }

    .MintModalBottomRightDesktop h2 {
        font-size: 16px;
    }
    
    .MintModalBottomRightDesktop h1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {

    .MintModalOverallContainer {
        top: 0;
        transform:none;
    }

    #navbarMintButton {
        border-radius: 5px;
        border: none;
        width: 60px;
        height: 30px;
    }

    #navbarMintButton h1{
        font-family: 'poppins';
        font-style: normal;
        font-weight: 900;
        font-size: 10px;
    }

    .ConnectButton {
        width: 100%;
        height: 30px;
        border-radius: 10px;
    }

    .ConnectButton h1 {
        font-size: 16px !important;
    }

    .MintButton {
        width: 150px;
        height: 30px;
        border-radius: 5px;
    }

    #LovechainMintButton h1 {
        font-size: 12px;
    }

    #bannerMintButton {
        width: 120px;
        height: 24px;
        background: none;
    }

    #bannerMintButton h1{
        font-size: 10px;
    }

    .MintModalHeaderText {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
    }

    .MintModalHeader h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000000;
    }

    .MintModalContent {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .MintModalTop {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MintModalLeft {
        display: flex;
        width: 100%;
        padding: 0;
        align-items: center;
        justify-content: center;
    }

    .MintModalLeft img{
        width: 220px;
        height: auto;
        border-radius: 20px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    }

    .MintModalRight {
        display: flex;
        flex-direction: column;
        width: 80%;
        justify-content: center;
        row-gap: 0.5rem;
        padding: 1rem 0 0 0;
    }

    .MintModalRight h1 {
        font-family: "poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 130%;
        padding: 0;
        margin: 0;
        color: #000000;
    }

    .MintModalRight p {
        font-size: 12px;
    }

    .MintModalPrice {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .MintModalPrice img {
        width: 20px;
        height: 20px;
    }

    .MintModalPrice h1 {
        font-size: 16px;
    }

    .MintModalQuantitySelector h2 {
        font-size: 12px;
    }

    .MintModalBottomDesktop {
        display:none;
    }

    .MintModalBottomMobileBorder {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 300px;
        border-radius: 20px;
        background: linear-gradient(180deg, #FC8836 0%, #F3ABF2 100%);
        padding: 1.5px;
    }

    .MintModalBottomMobile {
        display: flex;
        width: 299px;
        height: 299px;
        background: white;
        border-radius: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 0.5rem;
    }

    .MintModalTextMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .MintModalTextMobile h2 {
        font-family: 'poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        padding: 0;
        margin: 0;
    }

    .MintModalTextMobile h1 {
        font-family: 'poppins';
        font-style: normal;
        font-weight:700;
        font-size: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        padding: 0 0 1rem 0;
        margin: 0;
    }

    .MintModalBottomMobile img {
        width: 250px;
    }

    .MintModalQuantitySelector {
        justify-content: center;
        padding: 0 0 1rem 0;
    }

    .GiftHearLinkImg {
        margin-bottom: 12px;
    }
}
