.EventDuration {
    display: flex;
    flex-direction: column;
    padding: 8rem 0 0 0;
    font-family: 'poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
}

@media only screen and (max-width: 1200px) {
    .EventDuration {
        font-size: 18px;
        max-width: 800px;
    }
}

@media only screen and (max-width: 480px) {
    .EventDuration {
        font-size: 14px;
        max-width: 300px;
    }
}