.CongratulationsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.CongratulationsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.CongratulationsHeader h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.CongratulationsBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CongratulationsBody p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 130%;
    text-align: center;
    color: #000000;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.CongratulationsBody img {
    width: 600px;
    height: auto;
}

.CongratulationsFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CongratulationsFooter a {
    text-decoration: none;
}

.CongratulationsFooter button {
    width: 600px;
    height: 40px;
    background: linear-gradient(91.01deg, #F3ABF2 25.74%, #FC8836 79.27%);
    border-radius: 10px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CongratulationsFooter button p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
}

@media only screen and (max-width: 1000px) {
    .CongratulationsContainer {
        row-gap: 20px;
    }

    .CongratulationsHeader {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CongratulationsHeader h1 {
        font-size: 30px;
    }

    .CongratulationsBody {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .CongratulationsBody p {
        font-size: 20px;
        line-height: 130%;
        width: 500px;
    }

    .CongratulationsBody img {
        width: 500px;
    }

    .CongratulationsFooter {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .CongratulationsFooter button {
        width: 500px;
    }

    .CongratulationsFooter button p {
        font-size: 20px;
    }
}


@media only screen and (max-width: 786px) {
    .CongratulationsContainer {
        row-gap: 20px;
    }

    .CongratulationsHeader {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CongratulationsHeader h1 {
        font-size: 24px;
    }

    .CongratulationsBody {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .CongratulationsBody p {
        font-size: 16px;
        line-height: 130%;
        max-width: 320px;
    }

    .CongratulationsBody img {
        width: 400px;
    }

    .CongratulationsFooter {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .CongratulationsFooter button {
        width: 400px;
    }

    .CongratulationsFooter button p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .CongratulationsContainer {
        row-gap: 10px;
    }

    .CongratulationsHeader h1 {
        font-size: 24px;
    }

    .CongratulationsBody {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .CongratulationsBody p {
        font-size: 16px;
        line-height: 130%;
        padding: 0 1rem 0 1rem;
    }

    .CongratulationsBody img {
        width: 200px;
    }

    .CongratulationsFooter {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .CongratulationsFooter button {
        width: 200px;
    }

    .CongratulationsFooter button p {
        font-size: 12px;
    }
}
