body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ArgentCF';
  src: local('ArgentCF'), url(./fonts/argentcf-regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'ArgentCF-Bold';
  src: local('ArgentCF'), url(./fonts/argent-cf-bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'DIN';
  src: local('DIN'), url(./fonts/DIN.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'DIN-Black';
  src: local('DIN-Black'), url(./fonts/DIN-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'DIN-Bold';
  src: local('DIN-Bold'), url(./fonts/DIN-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DIN-Medium';
  src: local('DIN-Medium'), url(./fonts/DIN-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'DIN-2014-Extra-Bold';
  src: local('DIN-2014-Extra-Bold'), url(./fonts/DIN-2014-Extra-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: local('Poppins-ExtraBold'), url(./fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins-Black';
  src: local('Poppins-Black'), url(./fonts/Poppins/Poppins-Black.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'), url(./fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}