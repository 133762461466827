.CTAButtonContainer {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5rem 0 5rem 0;
}

.CTAButton {
    width: 900px;
    height: 90px;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CTAButton h1 {
    text-align: center;
    color: white;
    font-size: 30px;
    font-family: Poppins-Bold;
    font-weight: 900;
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
}

@media only screen and (max-width: 1200px) {
    .CTAButton {
        width: 700px;
        height: 80px;
    }
    
    .CTAButton h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 786px) {
    .CTAButton {
        width: 450px;
        height: 70px;
    }
    
    .CTAButton h1 {
        font-size: 20px;
    }

    .CTAButtonContainer {
        padding: 2rem 0 2rem 0;
    }
}

@media only screen and (max-width: 480px) {
    .CTAButton {
        width: 250px;
        height: 60px;
    }
    
    .CTAButton h1 {
        font-size: 16px;
    }
}