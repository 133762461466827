.LoadingContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
}

.ErrorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ErrorContainer p {
    color: black;
}