.ScrollToTopButton {
    width: 330px;
    height: 69px;
    border: 1px solid #000000;
    border-radius: 20px;
    font-family: 'poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
    cursor: pointer;
}

.ScrollToTopContainer {
    padding: 3rem 0 6rem 0;
}

@media only screen and (max-width: 1200px) {
    .ScrollToTopContainer {
        padding: 1.5rem 0 3rem 0;
    }
}

@media only screen and (max-width: 1000px) {
    .ScrollToTopButton {
        width: 250px;
        height: 50px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 786px) {
}

@media only screen and (max-width: 480px) {
    .ScrollToTopButton {
        width: 100px;
        height: 25px;
        font-size: 10px;
    }
}