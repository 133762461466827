.FAQContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1200px;
    align-items: center;
    padding: 5rem 0 5rem 0;
}

.FAQContainer h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #000000;
    text-align: center;
}

.FAQGroup {
    display: flex;
    width: 90%;
    flex-direction: column;
}

.FAQGroup h2 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
}
.FAQQuestion {
    display: flex;
    min-height: 68px;
}
.FAQQuestion b {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    letter-spacing: 0.03em;
    color: #000000;
        
    }

.FAQAnswer {
    display: flex;
    flex-direction: column;
}

.FAQAnswer p{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: left;
    color: #000000;
}

.FAQAnswer li{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: left;
    color: #000000;
    margin-bottom: 1rem;
}

.FAQAnswer a{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: left;
}
    

@media only screen and (max-width: 1600px) {
    .BannerContentContainer {
        row-gap: 2rem;
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1000px) {
    .FAQContainer {
        padding: 2rem 0 2rem 0;
    }
    .FAQGroup h2 {
        font-size: 24px;
    }

    .FAQQuestion b {
        font-size: 18px;
    }

    .FAQAnswer p{
        font-size: 14px;
    }
    
    .FAQAnswer li{
        font-size: 14px;
    }

    .FAQAnswer a{
        font-size: 12px;
    }
}

@media only screen and (max-width: 786px) {
    .FAQContainer h1 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 480px) {

    .FAQContainer {
        padding: 1rem 0 1rem 0;
    }

    .FAQContainer h1 {
        font-size: 16px;
    }

    .FAQGroup h2 {
        font-size: 14px;
    }

    .FAQQuestion b {
        font-size: 12px;
    }

    .FAQAnswer p{
        font-size: 12px;
    }
    
    .FAQAnswer li{
        font-size: 12px;
    }

    .FAQAnswer a{
        font-size: 10px;
    }
}
