.BannerContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -100px;
    /* right: 0; */
    left: 0;
    width: 100%;

}

.Banner {
    width: 100vw;
    height: auto;
    z-index: 0;
}

.BannerVideoContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 4;
}

.BannerVideoContainer::after {
    content:"";
    position: absolute;
    width: 100%; 
    height: 100%;
    background-color: rgba(59, 39, 69, 0.4);
    z-index: 100;
    left: 0;
    top: 0;
  }

.BannerVideoLeft {
    position: block;
    width: 50%;
    height: auto;
    z-index: 1;
}

.BannerVideoRight {
    position: block;
    width: 50%;
    height: auto;
    z-index: 1;
}

.BannerContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    row-gap: 3rem;
}

.BannerHeaderContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    /* height:85vh; */
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0;
}

.BannerHeaderContainer h1 {
    text-align: center;
    color: white;
    font-size: 60px;
    /* font-style: italic; */
    font-family: 'argent-cf';
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 2rem 0;
    justify-self: center;
    letter-spacing: 0.02em;
    text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
}

.BannerTextContainer {
    position: relative;
    width: 80%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    /* height:85vh; */
    align-items: center;
    justify-content: center;
    margin: 0 0 0 0;
    z-index: 1;
}


.BannerTextContainer h2 {
    text-align: center;
    color: white;
    font-size: 30px;
    font-family: 'poppins';
    font-weight: 600;
    line-height: 140%;
    padding: 0 0 0 0;
    margin: 0 0 2rem 0;
    justify-self: center;
    text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
}

.BannerButton {
    position: relative;
    z-index: 1;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    width: 300px;
    height:60px;
    font-family: 'poppins';
    font-size: 20px;
    font-weight: 900;
    line-height: 130%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.desktopText {
    display: block;
}

.mobileText {
    display: none;
}

@media only screen and (max-width: 1920px) {
    .BannerHeaderContainer h1 {
        font-size: 50px;
    }
    .BannerTextContainer {
        max-width: 1400px;
    }
    .BannerTextContainer h2 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 1600px) {
    .BannerContentContainer {
        row-gap: 2rem;
    }
}

@media only screen and (max-width: 1200px) {
    .BannerContentContainer {
        row-gap: 1rem;
    }
    .BannerHeaderContainer h1 {
        font-size: 40px;
    }
    .BannerTextContainer h2 {
        font-size: 20px;
    }

    .BannerContainer {
        top: -30px;
    }
}

@media only screen and (max-width: 1000px) {
    .BannerVideoContainer {
        flex-direction: column;
    }
    .BannerVideoLeft { 
        width: 100%;
        height: 48vh;
        object-fit: cover;
    }
    .BannerVideoRight { 
        width: 100%;
        height: 48vh;
        object-fit: cover;
    }
    .BannerContentContainer {
        row-gap: 0.5rem;
    }
    .BannerHeaderContainer h1 {
        font-size: 36px;
    }
    .BannerTextContainer h2 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 786px) {

    .BannerHeaderContainer h1 {
        padding: 0 0.5rem 0 0.5rem;
        margin: 1rem 0 1rem 0;
    }
    .BannerTextContainer h2 {
        padding: 0 0.5rem 0 0.5rem;
        margin: 0 0 1rem 0;
    }
    .desktopText {
        display: none;
    }
    
    .mobileText {
        display: block;
    }

    .BannerContainer {
        top: -20px;
    }
}

@media only screen and (max-width: 480px) {
    .BannerContentContainer {
        row-gap: 0rem;
    }
    .BannerContentContainer {
        row-gap: 0rem;
    }
    .BannerHeaderContainer h1 {
        font-size: 24px;
        padding: 0;
        margin: 1rem 0 1rem 0;
    }
    .BannerTextContainer h2 {
        font-size: 12px;
        padding: 0;
        margin: 0 0 1rem 0;
    }
}

@media only screen and (max-width: 300px) {
    .BannerContentContainer {
        row-gap: 0rem;
    }
    .BannerContentContainer {
        row-gap: 0rem;
    }
    .BannerHeaderContainer h1 {
        font-size: 16px;
        padding: 0;
        margin: 1rem 0 1rem 0;
    }
    .BannerTextContainer h2 {
        font-size: 8px;
        padding: 0;
        margin: 0 0 1rem 0;
    }
}