.PartnerLogos {
    display: flex;
    flex-direction: row;
    column-gap: 96px;
    align-items: center;
    padding-bottom: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%;
}

.PartnerLogoImg {
    width: auto;
    height: auto;
    margin-top:30px;
    cursor: pointer;
}
.PartnerLogoImg1 {
    width: auto;
    height: auto;
    margin-top:30px;
    cursor: pointer;
}


.PartnerLogoContainer h1{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #000000;
}

@media only screen and (max-width: 1200px) {
    .PartnerLogoImg {
        width: auto;
        height: auto;
    }
}

@media only screen and (max-width: 1000px) {
    .PartnerLogoImg {
        width: auto;
        height: auto;
    }
}

@media only screen and (max-width: 786px) {
    .PartnerLogoContainer h1{
        font-size: 32px;
    }

    .PartnerLogoImg {
        width: auto;
        height: auto;
    }
}

@media only screen and (max-width: 480px) {
    .PartnerLogoContainer h1{
        font-size: 24px;
    }

    .PartnerLogoImg {
        width: auto;
        height: auto;
    }

    .PartnerLogoImg1 {
        width: 100%;
    }
}