.ConnectText {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ConnectText h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #000000;
    text-align: center;
    padding: 0;
    margin: 0;
}

.ConnectText p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    text-align: center;
    padding: 0;
    margin: 0;
}

.ButtonContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 3rem;
    color: white;
}

.ConnectButtons {
    width: 350px;
    height: 200px;
    background: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    color: black !important;
    font-size: 20px !important;
    font-family: 'poppins' !important;
    font-weight: 700 !important;
    margin: 0 !important;
    border-width: 0px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.NFTImg {
    width: 400px;
    height: 400px;
}

.ConfirmTransactionPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ConfirmTransaction {
    align-self: center;
    justify-self: center;
    width: 400px;
    height: 60px;
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    color: white !important;
    font-size: 30px !important;
    font-family: "poppins" !important;
    font-weight: 900 !important;
    margin: 0 !important;
    border-width: 0px;
    cursor: pointer;
}

.WalletAddress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WalletAddress h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    text-align: center;
    padding: 0;
    margin: 0;
}

.WalletAddress p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    text-align: center;
    padding: 0;
    margin: 0;
}

/* @media only screen and (max-width: 1200px) {
    .PreRegButton {
        width: 350px;
        height: 60px;
        z-index: 5;
    }
    .PreRegButton h1 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 786px) {
    .PreRegButton {
        width: 250px;
        height: 50px;
        z-index: 5;
        border: 3px solid #FFFFFF;
    }
    .PreRegButton h1 {
        font-size: 16px;
    }
} */

@media only screen and (max-width: 480px) {

    .ConnectText {
        position: relative;
        width: 300px;
        left: 50%;
        transform: translatex(-50%)

    }

    .ConnectText h1 {
        font-size: 16px;
    }

    .ConnectText p {
        font-size: 12px;
    }

    .ButtonContainer {
        flex-direction: column;
        row-gap: 3rem;
    }

    .ConnectButtons {
        width: 200px;
        height: 100px;
        border-radius: 5px;
        font-size: 14px !important;
    }

    .ConnectButtons img {
        width: 40px;
        height: auto;
    }

    .ConfirmTransactionPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ConfirmTransaction {
        width: 200px;
        height: 40px;
        border-radius: 10px;
        text-align: center !important;
        color: white !important;
        font-size: 16px !important;
        font-family: "poppins" !important;
        font-weight: 900 !important;
        margin: 0 !important;
        border-width: 0px;
    }

    .WalletAddress h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        text-align: center;
        padding: 0;
        margin: 0;
    }
}
