.LoveChainContainer {
    display:flex;
    width: 100%;
    max-width: 1000px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0 3rem 0;
}

.LoveChainContainer h1{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;  
    color: #000000; 
}

.LoveChainTop {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 3rem;
    align-items: center;
    justify-content: center;
}

.LoveChainTopLeft {
    display: flex;
    width: 25%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}


.LoveChainTopRight {
    display: flex;
    width: 25%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.LoveChainTopCenter {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 3rem;
}

.LoveChainTopCenter h1 {
    font-style: 'poppins';
    font-size: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    background: linear-gradient(108.15deg, #F3ABF2 10.49%, #FC8836 91.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.TokenImageContainer {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    column-gap: 69px;
}

.TokenImageContainer img {
    width: 150px;
    height: 150px;
    border-radius: 20px;
}

.TokenImageContainer h1 {
    display: none;
}

.ClaimButtonContainer {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}


.LoveChainUtilContainer {
    display:flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 1rem 0;
}

.LoveChainUtilContainer h1{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 130%;
    text-align: justify;
    color: #000000;
}

.LoveChainUtilContainer ul{
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 130%;
    text-align: justify;
    color: #000000;
    list-style-type: none;
    padding: 0;
    line-height: 200%;
}

.LoveChainUtilContainer li:last-child {
    margin-bottom: 0px;
   }

.LoveChainUtilContainer li:before {
    content:  '';
    display:  inline-block;
    background-image: url("/public/bullet.png");
    width: 32px;
    height: 28px;
    background-size: 32px 28px;
    vertical-align:  middle;
    margin-right:  2rem;
}

.LoveChainUtilContainer li{
    align-self: center;
    justify-self: center;
    margin-bottom: 0.5rem;
}

.MintButtonContainer {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 2rem 0;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .LoveChainContainer {
        max-width: 800px;
    }
    .LoveChainContainer h1{
        font-size: 36px;
    }
    .LoveChainTopCenter h1 {
        font-size: 30px;
    }

    .LoveChainUtilContainer h1{
        font-size: 20px;
    }
    .LoveChainUtilContainer ul{
        font-size: 20px;
    }

    .LoveChainTopLeft img {
        width: auto;
        height: 400px;
    }

    .LoveChainTopRight img {
        width: auto;
        height: 400px;
    }

    .TokenImageContainer img {
        width: 140px;
        height: 140px;
    }  
}

@media only screen and (max-width: 1000px) {
    .LoveChainContainer {
        max-width: 700px;
    }
    .LoveChainContainer h1{
        font-size: 36px;
    }
    .LoveChainTopCenter h1 {
        font-size: 30px;
    }

    .LoveChainUtilContainer h1{
        font-size: 20px;
    }
    .LoveChainUtilContainer ul{
        font-size: 20px;
    }

    .LoveChainTopLeft img {
        width: auto;
        height: 300px;
    }

    .LoveChainTopRight img {
        width: auto;
        height: 300px;
    }

    .TokenImageContainer img {
        width: 120px;
        height: 120px;
    }  
}

@media only screen and (max-width: 786px) {
    .LoveChainContainer {
        max-width: 450px;
    }
    .LoveChainContainer h1{
        font-size: 32px;
    }
    .LoveChainTopCenter h1 {
        display: none;
    }

    .LoveChainUtilContainer h1{
        font-size: 16px;
    }
    .LoveChainUtilContainer ul{
        font-size: 16px;
    }

    .LoveChainTopLeft img {
        width: auto;
        height: 300px;
    }

    .LoveChainTopRight img {
        width: auto;
        height: 300px;
    }

    .TokenImageContainer {
        flex-direction: column;
        row-gap: 10px;
    }

    .TokenImageContainer  h1{
        display: block;
        font-size: 16px;
    }


    .TokenImageContainer img {
        width: 120px;
        height: 120px;
    }  
}

@media only screen and (max-width: 480px) {
    .LoveChainContainer {
        max-width: 300px;
        padding: 1rem 0 1rem 0;
    }
    .LoveChainContainer h1{
        font-size: 16px;
    }

    .LoveChainTop {
        column-gap: 10px;
    }

    .LoveChainTopCenter h1 {
        display: none;
    }

    .LoveChainUtilContainer h1{
        font-size: 10px;
    }
    .LoveChainUtilContainer ul{
        font-size: 10px;
    }

    .LoveChainTopLeft img {
        width: auto;
        height: 190px;
    }

    .LoveChainTopRight img {
        width: auto;
        height: 190px;
    }

    .TokenImageContainer {
        flex-direction: column;
        row-gap: 3px;
    }

    .TokenImageContainer  h1{
        display: block;
        font-size: 12px;
    }

    .TokenImageContainer img {
        width: 75px;
        height: 75px;
    }  

    .LoveChainUtilContainer li:before {
        margin-right:  0.5rem;
        width: 16px;
        height: 14px;
        background-size: 16px 14px;
    }
}
