.FooterContainer {
    display:flex;
    width: 100%;
    flex-direction: column;
    background: #000000;
    height: 180px;
}

.Socials {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.LeftBorder {
    width: 25%;
}

.RightBorder  {
    width:25%;
}

.LeftBorder hr {
    border-top: 1px solid #A9A9A9;
}

.RightBorder hr {
    border-top: 1px solid #A9A9A9;
}

.IconContainer {
    display: flex;
    width: 50%;
    flex-direction: row;
    column-gap: 15%;
    justify-content: center;
    align-items: center;
}

.copyright {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    margin: 0;
    width: 100%;
    position: relative;
    top: 40%;
}

.sociallogo {
    height: 25px;
}

.eightsianlogo {
    height: 50px;
}

@media only screen and (max-width: 1200px) {
    .FooterContainer {
        height: 150px;
    }
}

@media only screen and (max-width: 786px) {
    .FooterContainer {
        height: 100px;
    } 

    .copyright {
        font-size: 10px;
    }

    .LeftBorder {
        width: 20%;
    }
    
    .RightBorder  {
        width:20%;
    }

    .IconContainer {
        display: flex;
        width: 60%;
        flex-direction: row;
        column-gap: 10%;
        justify-content: center;
        align-items: center;
    }

    .sociallogo {
        height: 20px;
    }
    
    .eightsianlogo {
        height: 40px;
    }
}

@media only screen and (max-width: 480px) {

    .FooterContainer {
        height: 100px;
    }

    .copyright {
        font-size: 8px;
    }

    .IconContainer {
        display: flex;
        width: 60%;
        flex-direction: row;
        column-gap: 10%;
        justify-content: center;
        align-items: center;
    }

    .sociallogo {
        height: 15px;
    }
    
    .eightsianlogo {
        height: 32px;
    }
}