.GiftFormOverallContent {
    display: block;
    margin: auto;
    position: relative;

}

.GiftFormContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
}

.GiftFormContent h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #000000;
    margin: 0;
}

.GiftFormHeader {
    display: flex;
    flex-direction: row;
}

.GiftFormContent form {
    width: 1000px;
}

.GiftFormTop {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 50px;
}

.GiftContentModal hr {
    background: black;
    color: black;
    border-color: black;
    height: 3px;
}

.FormItem {
    display: flex;
    flex-direction: column;
}

.FormItem p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #C2C2C2;
    margin: 0 0 0 20px;
}

.FormItem input {
    border: 1px solid #000000;
    border-radius: 40px;
    width: 350px;
    height: 35px;
    font-family: 'poppins';
    font-size: 16px;
    color: #A175E5;
    padding: 0 0 0 20px;
}

#EmailInput > input {
    width: 400px;
}
#MessageInput > input {
    width: 100%;
    box-sizing: border-box;
}

.GiftFormSubmit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0 3rem 0;
}

.GiftFormSubmit button {
    background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
    border-radius: 20px;
    border: none;
    width: 100%;
    height: 60px;
    font-family: 'poppins';
    font-weight: 900;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    justify-self: center;
    align-self: center;
    cursor: pointer;
}

.GiftFormMiddle {
    display: flex;
    width: 80%;
    flex-direction: row;
    column-gap: 30px;
}

.GiftFormMiddle p {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #C2C2C2;
}

.GiftFormMiddle h1 {
    font-family: 'poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}

.GiftFormMiddleLeft {
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: center;
}

.GiftFormMiddleLeft img {
    width: 200px;
    height: auto;
    border-radius: 20px;
}

.GiftFormMiddleCenter {
    width: 70%;
}

.GiftFormEnd {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    row-gap: 10px;
}

.GiftFormRow {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.responsivebreak {
    display: block;
}

.GiftSuccessContent {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.GiftSuccessContentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
}

.GiftSuccessContentWrapper h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #000000;
    
}
.GiftSuccessContentWrapper p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 130%;
    /* or 32px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

@media only screen and (max-width: 1000px) {

    .GiftFormOverallContent {
        display: block;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .GiftFormContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        row-gap: 1rem;
    }

    .GiftFormContent h1 {
        font-size: 16px;
    }
    .GiftFormContent form {
        width: 90%;
    }

    .GiftFormTop {
        column-gap: 10px;
        align-items: center;
        justify-content: center;
    }

    .FormItem p {
        font-family: 'poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        display: flex;
        align-items: center;
        color: #C2C2C2;
        margin: 0 0 0 0px;
    }
    
    .FormItem input {
        border-radius: 10px;
        width: 130px;
        height: 20px;
        font-size: 10px;
        padding: 0 0 0 10px;
    }

    .GiftFormMiddle {
        display: flex;
        width: 100%;
        flex-direction: row;
        column-gap: 20px;
    }

    .GiftFormMiddleLeft img {
        width: 100px;
    }

    .GiftFormMiddle p {
        font-family: 'poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        color: #C2C2C2;
    }
    
    .GiftFormMiddle h1 {
        font-family: 'poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #000000;
    }

    .GiftFormRow {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
    }

    #EmailInput > input {
        width: 130px;
    }

    .GiftFormSubmit button {
        background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
        border-radius: 10px;
        border: none;
        width: 100%;
        height: 40px;
        font-family: 'poppins';
        font-weight: 900;
        font-size: 14px;
    }

    .responsivebreak {
        display: none;
    }


    .GiftSuccessContent {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        height: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .GiftSuccessContentWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 1rem;
    }

    .GiftSuccessContentWrapper img {
        width: 300px;
        height: 300px;
    }

    .GiftSuccessContentWrapper h1 {
        font-size: 30px;
        
    }

    .GiftSuccessContentWrapper p {
        font-size: 16px;
        padding: 0 1rem 0 1rem;
    }
}

@media only screen and (max-width: 480px) {

    .GiftFormOverallContent {
        display: block;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .GiftFormContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        row-gap: 1rem;
    }

    .GiftFormContent h1 {
        font-size: 16px;
    }
    .GiftFormContent form {
        width: 90%;
    }

    .GiftFormTop {
        column-gap: 10px;
        align-items: center;
        justify-content: center;
    }

    .FormItem p {
        font-family: 'poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        display: flex;
        align-items: center;
        color: #C2C2C2;
        margin: 0 0 0 0px;
    }
    
    .FormItem input {
        border-radius: 10px;
        width: 130px;
        height: 20px;
        font-size: 10px;
        padding: 0 0 0 10px;
    }

    .GiftFormMiddle {
        display: flex;
        width: 100%;
        flex-direction: row;
        column-gap: 20px;
    }

    .GiftFormMiddleLeft img {
        width: 100px;
    }

    .GiftFormMiddle p {
        font-family: 'poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        color: #C2C2C2;
    }
    
    .GiftFormMiddle h1 {
        font-family: 'poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #000000;
    }

    .GiftFormRow {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
    }

    #EmailInput > input {
        width: 130px;
    }

    .GiftFormSubmit button {
        background: linear-gradient(277.6deg, #FC8836 3.05%, #F3ABF2 96.42%);
        border-radius: 10px;
        border: none;
        width: 100%;
        height: 40px;
        font-family: 'poppins';
        font-weight: 900;
        font-size: 14px;
    }

    .responsivebreak {
        display: none;
    }


    .GiftSuccessContent {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        height: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .GiftSuccessContentWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2rem;
    }

    .GiftSuccessContentWrapper img {
        width: 150px;
        height: 150px;
    }

    .GiftSuccessContentWrapper h1 {
        font-size: 24px;
        
    }

    .GiftSuccessContentWrapper p {
        font-size: 12px;
        padding: 0 1rem 0 1rem;
    }

}