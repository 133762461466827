.ProfileNFTCarousel {
    display: flex;
    flex-direction: column;
    padding: 3rem 0 0 0;
    width: 100%;
    height: 100%;
}

.ProfileNFTCarousel h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 130%;
    color: #FC8836;
}

.carouselItem {
    display: block;
    height: 100%;
}

.carouselItem img{
    width: 200px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.carouselItem h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #FC8836;
}

@media only screen and (max-width: 1600px) {
    .carouselItem img{
        width: 160px;
        height: 160px;
    }
}

@media only screen and (max-width: 800px) {
    .ProfileNFTCarousel {
        padding: 1rem 0 0 0;
    } 
    .carouselItem img{
        width: 120px;
        height: 120px;
    }
}

@media only screen and (max-width: 480px) {
    .ProfileNFTCarousel {
        padding: 1rem 0 0 0;
    } 

    .ProfileNFTCarousel h1 {
        font-size: 16px;
    }

    .carouselItem img{
        width: 100px;
        height: 100px;
    }

    .carouselItem h1 {
        font-size: 10px;
        font-weight: 500;
    }
}