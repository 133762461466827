.CountdownContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    padding: 5rem 0 0 0;
}

.CountdownContainer h1 {
    font-family: Poppins-Bold;
    font-weight: 700;
    font-size: 30px;
    color: black;
    letter-spacing: 0.2em;
    padding: 0 1rem 0 1rem;
}

.CountdownClockContainer {
    display:flex;
    width: 80%;
    flex-direction: row;
    column-gap: 10px;
    padding: 1rem 0 1rem 0;
    justify-content: center;
    align-items: center;
}

.CountdownColumn {
    display:flex;
    flex-direction: column;
    width:19%;
}

.CountdownColumn h1 {
    font-family: 'argent-cf';
    font-size: 40px;
    color: black;
}

.CountdownColumn p {
    font-family: 'argent-cf';
    font-size: 24px;
    color: black;
}

.Divider {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vl2 {
    border-left: 3px solid #ABABAB;
    height: 178px;
    position: relative;
    top: 0;
  }
.responsivebreak {
    display: none;
}
@media only screen and (max-width: 1600px) {
    .CountdownColumn h1 {
        font-size: 36px;
    }

    .CountdownColumn p {
        font-size: 20px;
    }

    .CountdownContainer h1 {
        font-size: 28px;
    }

    .vl2 {
        border-left: 3px solid #ABABAB;
        height: 140px;
        position: relative;
        top: 0;
      }
}

@media only screen and (max-width: 1000px) {
    .CountdownColumn h1 {
        font-size: 36px;
    }

    .CountdownColumn p {
        font-size: 20px;
    }

    .CountdownContainer h1 {
        font-size: 20px;
    }

    .vl2 {
        border-left: 3px solid #ABABAB;
        height: 100px;
        position: relative;
        top: 0;
      }
}

@media only screen and (max-width: 786px) {
    .CountdownClockContainer {
        display:flex;
        width: 100%;
        flex-direction: row;
        column-gap: 10px;
        padding: 1rem 0 1rem 0;
        justify-content: center;
        align-items: center;
    }

    .CountdownColumn h1 {
        font-size: 30px;
    }

    .CountdownColumn p {
        font-size: 16px;
    }

    .CountdownContainer h1 {
        font-size: 16px;
    }

    .vl2 {
        border-left: 2px solid #ABABAB;
        height: 100px;
        position: relative;
        top: 0;
      }
      .CountdownContainer {
        padding: 2rem 0 0 0;
        row-gap: 1rem;
    }
    .responsivebreak {
        display: block;
    }
}

@media only screen and (max-width: 480px) {
    .CountdownClockContainer {
        column-gap: 10px;
        padding: 0.5rem 0 0.5rem 0;
    }

    .CountdownColumn h1 {
        font-size: 26px;
    }

    .CountdownColumn p {
        font-size: 14px;
    }

    .CountdownContainer h1 {
        font-size: 14px;
    }

    .vl2 {
        display: none
      }
    .CountdownClockContainer {
        column-gap: 0;
    }

    .responsivebreak {
        display: block;
    }
}