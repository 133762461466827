.HeaderContainer {
    display:flex;
    width: 100%;
    height: 90px;
    flex-direction: row;
    column-gap: 10px;
    padding: 1rem 0 1rem 0;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #ffffff;
    border-radius: 0px 0px 30px 30px;
    filter: drop-shadow(5px 5px 5px rgba(128, 128, 128, 0.5));
}

.NavbarBlankContainer {
    display:flex;
    width: 33%;
}

.NavbarLogoContainer {
    display:flex;
    width: 34%;
    flex-direction: row;
    column-gap: 10px;
}

.LeftLogo {
    display:flex;
    width:49%;
    height: 100%;
    justify-self: flex-end;
    justify-content: flex-end;
    align-items: center;
}

.Divider {
    width: 2%;
}

.vl {
    border-left: 1.5px solid #BCBCBC;
    height: 50px;
    position: relative;
    left: 50%;
    margin-left: -3px;
  }

.RightLogo {
    display:flex;
    width: 49%;
    height: 100%;
    align-self: center;
    justify-self: flex-start;
    justify-content: flex-start;
    align-items: center;
}

.LeftLogo a img{
    width: 140px;
}
.RightLogo a img{
    width: 140px;
    align-self: center;
    align-items: center;
    justify-self: center;
    justify-content: center;
}

.NavbarLoginButtons {
    display: flex;
    width: 33%;
    justify-content: flex-end;
    align-items: center;
}

.NavbarLoginButtonsWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    align-items: center;
    padding: 0 30px 0 0;
}

.dropdown {
    display: none;

}

.dropdown img {
    width: 20px;
    height: 20px;
    margin: 0 30px 0 0
}
.dropdown button {
    background: none;
    border: none;
}
/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    left: 80%;
    background: none;
    z-index: 999;
  }

  .dropdown-content > * {
    margin: 3px 0
  }

  .dropdown-content > *:first-child {
    margin-top: 0
  }

@media only screen and (max-width: 1200px) {
    .LeftLogo a img{
        width: 90px;
    }
    .RightLogo a img{
        width: 90px;
    }

    .NavbarLoginButtons {
        column-gap: 12px;
    }

    .HeaderContainer {
        height: 50px;
    }
}

@media only screen and (max-width: 1000px) {

    .NavbarLoginButtonsWrapper {
        visibility: show;
    }
}

@media only screen and (max-width: 786px) {

    .NavbarLoginButtonsWrapper {
        display:none
    }

    .HeaderContainer {
        height: 20px;
        max-height: 20px;
    }
    
    .LeftLogo a img{
        width: 50px;
    }
    .RightLogo a img{
        width: 50px;
    }
    .vl {
        height: 25px;
      }

    .dropdown {
        display: block;
    }
}