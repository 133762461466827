.QuizNumberBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 77px;
    width: 77px;
    margin-right: 20px;
    border-radius: 10px;
    border: 1px solid #A9A9A9;
}

.ActiveBox {
    border: 2px solid #FC8836;
}

.ActiveDot {
    height: 6px;
    width: 6px;
    border-radius: 100px;
    background: #FC8836;
}

.QuizNumber {
    font-size: 30px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    color: #FC8836;
}

@media only screen and (max-width: 1200px) {
    .QuizNumberBox {
        margin-right: 12px;
        height: 60px;
        width: 60px;
    }

    .QuizNumber {
        font-size: 20px;
    }
}

@media only screen and (max-width: 800px) {
    .QuizNumberBox {
        margin-right: 0;
        height: 50px;
        width: 50px;
    }

    .QuizNumber {
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {
    .QuizNumberBox {
        margin-right: 0;
        height: 35px;
        width: 35px;
    }

    .QuizNumber {
        font-size: 12px;
    }
}