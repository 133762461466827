.Quiz {
    max-width: 1200px;
    padding: 80px 0;
    font-family: 'poppins';
}

.Quiz a {
    text-decoration: none;
    cursor: pointer;
}

.QuizHeader h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #000000;
    margin: 0;
}

.QuizHeader p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.QuizHeader p u{
    color: blue;
}


.QuizHeaderContainer {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 80px;
}

.QuizHeaderContainer button {
    position: absolute;
    top: 10%;
    left: 0;
}

.QuizHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.QuestionHeader {
    font-size: 30px;
    font-weight: 700;
    line-height: 39px;
    text-align: center;
    color: #FC8836;
    margin-bottom: 17px;
}

.QuizDescription {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

.ButtonContainer {
    width: 600px;
    margin: 0 auto;
}

.AnswerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
}

.AnswerList {
    margin-bottom: 40px;
    list-style: none;
    width: 100%;
    padding-left: 0;
}

.AnswerWrapper {
    margin: 24px auto 30px auto;
    border-radius: 30px;
    border: 1px solid #A9A9A9;
    padding-left: 51px;
}

.ActiveWrapper {
    border-color: #FC8836;
}

.AnswerContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.AnswerLabel {
    font-size: 20px;
    font-weight: 700;
    line-height: 39px;
    text-align: center;
    color: #FC8836;
}

.AnswerValue {
    flex: 1;
    display: flex;
    padding-left: 80px;
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    text-align: left;
    padding-right: 20px;
}

.ButtonWrapper {
    display: flex;
    justify-content: center;
}

.QuizError img {
    width: 240px;
    height: 240px;
}

.SuccessBack {
    text-decoration: underline;
    margin: 14px auto auto auto;
    font-size: 20px;
    font-weight: 700;
    color: #A9A9A9;
}

@media only screen and (max-width: 1200px) {
    .Quiz {
        max-width: 980px;
    }

}

@media only screen and (max-width: 480px) {
    .Quiz {
        padding: 24px 24px 200px 24px;
    }

    .QuizContent h1 {
        font-size: 20px
    }

    .QuizHeader p {
        font-size: 15px;
    }

    .QuizHeaderContainer {
        margin-bottom: 10px;
    }

    .QuizHeaderContainer button {
        top: 0;
    }

    .QuizHeaderContainer svg {
            height: 20px;
            width: 10px;
    }

    .QuizDescription {
        font-size: 15px;
    }

    .ButtonContainer {
        width: 100%;
    }

    .AnswerWrapper {
        margin-bottom: 24px;
        margin-top: 0;
        padding-left: 24px;
    }

    .AnswerList {
        margin-bottom: 24px;
    }

    .AnswerValue {
        padding-left: 40px;
    }

    .QuizError img {
        width: 120px;
        height: 120px;
    }

    .SuccessBack {
        font-size: 15px;
    }
}
